@use '../util' as *;

.toggle {
    border: none;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 3rem;
  
    label {
      font-size: rem(14);
      font-weight: 700;

      &[for="dark"]{
       line-height: rem(24);
       margin-right: rem(13);
      }
    }
  
    &__wrapper {
      position: relative;
      height: rem(24);
      grid-column: 2 / 3;
    }
  
    input[type='radio'] {
      margin: 0 rem(-2) 0 rem(-2);
      opacity: 0;
      width: rem(22);
      height: rem(24);
  
      &:focus ~ .toggle__button {
        border: 2px solid white;
        background: var(--toggle);
      }
    }
  
    &__background {
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      border-radius: rem(12);
      background: linear-gradient(270deg, var(--toggle-bg-end), var(--toggle-bg-start));
      pointer-events: none;
    }
  
    &__button {
      position: absolute;
      left: rem(3);
      top: rem(3);
      right: 100%;
      width: rem(18);
      height: rem(18);
      border-radius: 50%;
      background-color: var(--toggle);
      transition: all 100ms ease-in-out;
    }
  
    #light:checked ~ .toggle__button {
      left: calc(100% - 21px);
      right: 3px;
    }
  }